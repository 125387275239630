import {  Component,  ElementRef,  NgZone,  OnInit,  AfterViewInit, Renderer2,  ViewChild, OnDestroy} from '@angular/core';
import { AppHost, Reaction } from '@yoyo/types';
import {  HostStateService,  AppStateService,  ReactionService,TelemetryService} from '@yoyo/services';
import { DynamicComponent } from '../setup/setup.component';
import { Subject, interval } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'step-record',
  templateUrl: './record.component.html',
})
export class ReactionRecordStep implements OnInit, DynamicComponent, OnDestroy, AfterViewInit {     //step 5
  reaction_data: Reaction;
  @ViewChild('reveal_video') reveal_video: ElementRef<HTMLVideoElement>;
  isXLBreakpoint = false;
  private timeExtend: number = 0;

  // Overlays
  //revealContentShown: boolean = false;
  revealContentShown_top: boolean = false;
  revealContentShown_bottom: boolean = false;
  reveal_overlay_content = '';
  reveal_overlay_content_top = '';
  reveal_overlay_content_bottom = '';
  private config_ordered_bottom: any;
  private config_ordered_top: any;
  private currentLoopIndex_bottom: number = 0;
  private currentLoopIndex_top: number = 0;
  private timeLeft: number = 0;
  private timeConsumed: number = 0;
  private timeConsumed_top: number = 0;
  private timeConsumed_bottom: number = 0;
  private destroy$: Subject<void> = new Subject<void>();

  //Progress
  percentProgVal: number = 0;
  startZero: boolean = true;
  spinTitle: string = "";
  spinner_duration = 0;
  prog_animation = false;
  overallDuration: number = 0;
  private spinLoopMaxCount: number = 0;
  private spinLoopCurrentCount: number = 0;
  private spinDecrement: number = 100;    //this is the await value in the spin - feeds into the spinLoopCount

  // State Controls
  is_reveal_video_ready = false;
  has_reaction_started = false;
  devicesError = false;
  host_config: AppHost;
  videoDuration: number;
  private isPaused: boolean = true;
  videoCompleted: boolean = false;
  permission_given: boolean = true;
  startBtnState: boolean = true; 
  private exitCondition: boolean = false;
  private stepGoto: number;

  //button labels
  btnTxt_primary_init: string = "";
  btnTxt_scnd_init: string = "";
  btnTxt_primary_act: string = "";
  btnTxt_scnd_act: string = "";

  actionBtn: string = "pause"; 


   // video
   videoPoster: string;
   webCamPoster: string;
   videoLoaded: boolean = false;
   public startRecording: boolean = false

  // Record RTC
  private stream: MediaStream;
  private recorder: any;
  protected duration: number;
  private display_reveal = true;

  // Reveal Overlay
  //show_reveal_content = false;


  protected currentTime: number;
  current_reaction: Reaction;

  constructor(
    private renderer: Renderer2,
    private rs: ReactionService,
    private ngZone: NgZone,
    private root_state: AppStateService,
    private hs: HostStateService,
    private app_state: AppStateService,
    private el: ElementRef,
    private telemetry: TelemetryService,
  ) {
    this.telemetry.startRecordingLoad();
    this.reaction_data = this.root_state.current_reaction;
    this.host_config = this.hs.currentHostConfig;
    this.current_reaction = this.app_state.current_reaction;
    this.app_state.redoButtonToPointToStep = 5;
    this.timeExtend = this.host_config.time_control?.extend_live_length;
    this.videoPoster = this.host_config?.video_posters?.live_video_poster;  
    this.webCamPoster = this.host_config?.video_posters?.live_webCam_poster;
    this.app_state.faultReturnStep = 5; 
  }


//**********        LifeCycle hooks */
//**********        LifeCycle hooks */
//**********        LifeCycle hooks */
//**********        LifeCycle hooks */

  async ngOnInit() {
    //console.log("RECORD - initialised");
    //console.log("has_reaction_started= " + this.has_reaction_started + " is_reveal_video_ready= " + this.is_reveal_video_ready);

    this.btnTxt_primary_init = this.host_config.app_content.experience.step.step5.prmy_btn_loading;
    this.btnTxt_scnd_init = this.host_config.app_content.experience.step.step5.scnd_btn_int;
    this.btnTxt_primary_act = this.host_config.app_content.experience.step.step5.prmy_btn_act;
    this.btnTxt_scnd_act = this.host_config.app_content.experience.step.step5.scnd_btn_act; 

    try {
      this.devicesError = await this.checkEnumerateDevices();
      if (!!this.devicesError) {
        alert('Device not supported!');
        // TODO JM: Handle error on DOM
      }
      this.is_reveal_video_ready = true;
    } catch (error) {
      console.error('Error: ngOnInit()', error);
    }

    const termsLink = this.el.nativeElement.querySelector('#termsLink');

    if (termsLink) {
      this.renderer.listen(termsLink, 'click', (event) => {
        console.log('link listened to');
        this.endReaction(false);
        event.preventDefault();
        this.app_state.returnStep = 5;
        this.telemetryRecord(13);
        this.app_state.reactionStepValue$.next(13);
      });
      }

    if (window.innerWidth >= 1024){
    //  console.log('size is xl because: ' + window.innerWidth);
      this.isXLBreakpoint = true;
    } else {
      this.isXLBreakpoint = false;
    // console.log('size is not xl because: ' + window.innerWidth);
    }

    this.config_ordered_top = this.host_config?.overlays?.exp_live_top?.sort(
      (a, b) => a.index - b.index
    );
 //   console.log('config_ordered_top: ' + JSON.stringify(this.config_ordered_top, null,2));
  //  console.log('config_ordered_top test: ' + JSON.stringify(this.config_ordered_top[1], null,2));

    this.config_ordered_bottom = this.host_config?.overlays?.exp_live_bottom?.sort(
      (a, b) => a.index - b.index
    );

    //console.log('config_ordered: ' + this.config_ordered);
    
    //check that this is how to do it
    this.startBtnState = true;
  }

  async ngAfterViewInit() {
    this.telemetry.stopRecordingLoad();
    this.telemetry.startRecordingUser();
  }

  private telemetryRecord(next: number) {
    this.telemetry.stopRecording(5, this.host_config?.app_content?.experience?.step?.step5?._module, next);
   // console.log('step telemetery is: ' + JSON.stringify(this.telemetry.stepTrackArray, null,2));
  }


  ngOnDestroy() {
    //console.log('RECORD - destroy');
    this.destroy$.next();
    this.destroy$.complete();
  }


//**********        this is the progress code block */
//**********        this is the progress code block */
//**********        this is the progress code block */
//**********        this is the progress code block */

private async setupProgessSpinner() {
  this.overallDuration = this.videoDuration + this.timeExtend;
  console.log('overallDuration: ' + this.overallDuration);
  if (isNaN(this.overallDuration)) {
    this.faultDetected('duration');
  }

  this.spinner_duration = this.overallDuration * 1000;
  //this.percentProgVal = this.overallDuration;
  this.spinLoopMaxCount = this.spinner_duration / this.spinDecrement;
  //console.log('spinLoopMaxCount is set at: ' + this.spinLoopMaxCount);

  this.spinnerBurnDown();
};

private async spinnerBurnDown() {
  let curPercent = 0;
  for (let counter = this.spinLoopCurrentCount; counter < this.spinLoopMaxCount;) {
    curPercent = (this.spinLoopCurrentCount/this.spinLoopMaxCount) * 100;
    this.percentProgVal = 100 - curPercent;
 //   console.log('SPINNER - percentProgVal: ' + this.percentProgVal + ' spinLoopCurrentCount= ' + this.spinLoopCurrentCount + ' spinLoopMaxCount= ' + this.spinLoopMaxCount);
    this.spinTitle = Math.round((this.percentProgVal/100) * this.overallDuration).toString();
    
    await this.delay(this.spinDecrement);
    if (this.exitCondition) {
      //console.log('RECORD - spinnerBurn down exit condition')
      return;
    } else {
        if (this.isPaused){
          return    //exist this loop becaue pause duration is unknown.  Will start again on resume.
      } else {
        counter++;
        this.spinLoopCurrentCount = counter;
      }

      if (counter >= this.spinLoopMaxCount){
        console.timeLog('RECORD - Burndown complete');
        this.endReaction(false);        //false means process ended via the timer completing.  Note true means users exited
      }
    };
  }
}

private async delay(ms: number): Promise<void> {
  return new Promise<void>(resolve => setTimeout(resolve, ms));
}


//**********        this is the overlay code block */
//**********        this is the overlay code block */
//**********        this is the overlay code block */
//**********        this is the overlay code block */

  private async startCountdown(waiting: number): Promise<number> {
    //console.log('starting the time for: ' + waiting);
    let timeConsumedLocal: number = 0;
    const completedPromise = new Promise<number>((resolve) => {
      interval(100)
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe(count => {
          const remainingTime = waiting - count / 10;
          timeConsumedLocal = count / 10;
          this.timeLeft = Math.max(remainingTime, 0);
          if (this.timeLeft <= 0) {
            resolve(0);
          }
        });

        this.destroy$.subscribe(() => {
          //console.log('destroy$ emitted in startCountdown');
            resolve(timeConsumedLocal); // Resolve the promise when destroy$ is emitted
        });
    });
    return await completedPromise; // Wait until the promise is resolved (countdown is completed)
  }


  private async OverlayLoopController_top() {
   // console.log('TOP - loop about to start from: ' + this.currentLoopIndex_top + ' MAX TOP Loop = ' + this.config_ordered_top.length);
    for (let counter_top = this.currentLoopIndex_top; counter_top < this.config_ordered_top.length;) {
      //console.log('config is: ' + JSON.stringify(this.config_ordered[i], null, 2));
      if (this.isPaused) {
        // Pause the loop if the overlay timer is paused
        this.currentLoopIndex_top = counter_top;
        console.log('paused loop exit. ' + this.currentLoopIndex_top);
        return;
      }

      if(!this.revealContentShown_top) {      //false means we must be waiting to show
          const waiting_top = (this.config_ordered_top[counter_top].show_at/1000) - this.timeConsumed_top;
     //     console.log('TOP- SHOW block true. Loop count=' + counter_top + ' Stats: waiting='+ waiting_top + ' config val='+this.config_ordered_top[counter_top].show_at+' timeConsumed=' + this.timeConsumed_top);
          this.timeConsumed_top = await this.startCountdown(waiting_top) +  this.timeConsumed_top;
       //   console.log("TOP SHOW - await complete with timeConsumed_top = " + this.timeConsumed_top);
          
          if (this.exitCondition) {
            //console.log('RECORD - OverlayLoopController down exit condition @shown await')
            return;
          } else {
              if (this.isPaused){
          //      console.log('doing nothing because paused');
              } else {
                this.timeConsumed_top = 0;
                this.revealContentShown_top = true;
       //         console.log('TOP show phase complete for loop: ' + this.currentLoopIndex_top);
              }
          }
      }
      
      if (this.revealContentShown_top){
          this.reveal_overlay_content_top = this.replaceConfigString(this.config_ordered_top[counter_top].message);
          const waiting_top = (this.config_ordered_top[counter_top].hide_at/1000) - this.timeConsumed_top;
       //   console.log('TOP - HIDE block true. Loop count=' + counter_top + 'Stats: waiting='+ waiting_top + ' config val='+ this.config_ordered_top[counter_top].hide_at+' timeConsumed=' + this.timeConsumed_top);
          this.timeConsumed_top = await this.startCountdown(waiting_top) +  this.timeConsumed_top;
   //      console.log("HIDE - await complete with timeConsumed = " + this.timeConsumed);
          if (this.exitCondition) {
   //         console.log('RECORD - OverlayLoopController down exit condition @hide await')
            return;
          } else {           
            if (this.isPaused){
   //           console.log('doing nothing because paused');
            } else {
              this.timeConsumed_top = 0;
              this.revealContentShown_top = false;
       //       console.log('TOP Hide phase complete for loop: ' + this.currentLoopIndex_top);
              this.currentLoopIndex_top++;
              counter_top++;
            }
          }
        }
   //    console.log('TOP loop end with currentLoopIndex: ' + this.currentLoopIndex_top);
    }     //for loop closing bracket
  }       //OverlayLoopController


  private async OverlayLoopController_bottom() {
   // console.log('OVERLAY  BOTTOM - loop about to start from: ' + this.currentLoopIndex_bottom);
    for (let counter = this.currentLoopIndex_bottom; counter < this.config_ordered_bottom.length;) {
      //console.log('config is: ' + JSON.stringify(this.config_ordered[i], null, 2));
      if (this.isPaused) {
        // Pause the loop if the overlay timer is paused
        this.currentLoopIndex_bottom = counter;
        //console.log('paused loop exit. ' + this.currentLoopIndex);
        return;
      }

      if(!this.revealContentShown_bottom) {      //false means we must be waiting to show
          const waiting = (this.config_ordered_bottom[counter].show_at/1000) - this.timeConsumed_bottom;
          //console.log('OVERLAY BOTTOM SHOW block true. Stats: waiting='+waiting + ' config val='+this.config_ordered[counter].show_at+' timeConsumed=' + this.timeConsumed);
          this.timeConsumed_bottom = await this.startCountdown(waiting) +  this.timeConsumed_bottom;
     //     console.log("SHOW - await complete with timeConsumed = " + this.timeConsumed);
          
          if (this.exitCondition) {
            //console.log('RECORD - OverlayLoopController down exit condition @shown await')
            return;
          } else {
              if (this.isPaused){
          //      console.log('doing nothing because paused');
              } else {
                this.timeConsumed_bottom = 0;
                this.revealContentShown_bottom = true;
     //           console.log('BOTTOM Show phase complete for loop: ' + this.currentLoopIndex_bottom);
              }
          }
      }
      
      if (this.revealContentShown_bottom){
          this.reveal_overlay_content_bottom = this.replaceConfigString(this.config_ordered_bottom[counter].message);
          const waiting = (this.config_ordered_bottom[counter].hide_at/1000) - this.timeConsumed_bottom;
    //      console.log('OVERLAY - HIDE block true. Stats: waiting='+waiting + ' config val='+this.config_ordered[counter].hide_at+' timeConsumed=' + this.timeConsumed);
          this.timeConsumed_bottom = await this.startCountdown(waiting) +  this.timeConsumed_bottom;
   //      console.log("HIDE - await complete with timeConsumed = " + this.timeConsumed);
          if (this.exitCondition) {
   //         console.log('RECORD - OverlayLoopController down exit condition @hide await')
            return;
          } else {           
            if (this.isPaused){
   //           console.log('doing nothing because paused');
            } else {
              this.timeConsumed_bottom = 0;
              this.revealContentShown_bottom = false;
       //      console.log('BOTTOM Hide phase complete for loop: ' + this.currentLoopIndex_bottom);
              this.currentLoopIndex_bottom++;
              counter++;
            }
          }
        }
      // console.log('BOTTOM loop end with currentLoopIndex: ' + this.currentLoopIndex_bottom);
    }     //for loop closing bracket
  }       //OverlayLoopController

  private replaceConfigString(string_value: string) {
    return string_value
      .replace(
        '{{sender.first_name}}',
        this.reaction_data?.sender_details?.first_name
      )
      .replace(
        '{{sender.last_name}}',
        this.reaction_data?.sender_details?.last_name
      )
      .replace(
        '{{receiver.first_name}}',
        this.reaction_data?.receiver_details?.first_name
      )
      .replace(
        '{{gift_name}}',
        this.reaction_data?.product?.product_details?.label || ''
      )
      .replace(
        '{{gift_image}}',
        this.reaction_data?.product?.product_details?.image_src || ''
      )
      .replace(
        '{{gift_description}}',
        this.reaction_data?.product?.product_details?.product_description || ''
      )
      .replace('{{gift_message}}', this.reaction_data?.gift_message || '');
  }

  private async checkEnumerateDevices() {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      if (Array.isArray(devices) && !!devices.length) {
   //     console.log('Device detected');
        return false;
      } else {
        return true;
      }
    } catch (err) {
      return true;
    }
  }

  //**********        this is the control block */
  //**********        this is the control block */
  //**********        this is the control block */
  //**********        this is the control block */

  private async startReveal(): Promise<void> {
 //   console.log("start reveal")
    try {
      if (this.recorder) {            //purpose unclear
        return;
      }
      this.startRecording = true;
      this.has_reaction_started = true;
      this.videoDuration = this.reveal_video.nativeElement.duration;
   //   console.log('video duration '+ this.videoDuration);

      this.isPaused = false;
      this.startZero = false;
      //play condition
      this.reveal_video.nativeElement.play();

      this.renderer.listen(this.reveal_video.nativeElement, 'play', () => {
        
      });

      this.renderer.listen(this.reveal_video.nativeElement, 'pause', () => {
      
      });

      this.renderer.listen(this.reveal_video.nativeElement, 'ended', () => {
 //       console.log('Video element ended');
        this.videoEnd();
      });

    } catch (error) {
      console.error('Error: ngOnInit()', error);
    }
  }

  private videoEnd() {
   // console.log('Video end control function initiated');
    this.videoCompleted = true;
    this.prog_animation = true;
    this.reveal_video.nativeElement.load();         //displaying the poster on video end.
  }

  private ActionControl() {
//    console.log('SPINNER - percentProgVal: ' + this.percentProgVal + ' spinLoopCurrentCount= ' + this.spinLoopCurrentCount + ' spinLoopMaxCount= ' + this.spinLoopMaxCount);
    if (this.isPaused) {        //paused so resuming
 //    console.log('CONTROL - state = paused -> action = resume.')
      this.actionBtn = "Pause"
      this.isPaused = false;
      this.prog_animation = true;
      this.OverlayLoopController_top();
      this.OverlayLoopController_bottom();
      this.spinnerBurnDown();         
      if (!this.videoCompleted){                      //video still playing so resuming
          this.reveal_video.nativeElement.play();     // Resume video 
      }                                               // no else bc video is done - condition of overal going longer than video
    } else {                                          //video still playing so resuming
//     console.log('CONTROL - state = playing -> action = pause')
      this.actionBtn = "Resume"
      this.prog_animation = false;
      this.isPaused = true;
      this.destroy$.next();
      if (!this.videoCompleted){ 
        this.reveal_video.nativeElement.pause(); // Resume video
      }
    }
  }

  async onReactionComplete(value: Blob): Promise<void> {      //unclear on callign function
    try {
  //    console.log('CONTROL - Reaction Complete');
      this.rs.reaction_recording_blob = value;
      this.recorder = null;
      this.stream = null;
    } catch (err) {
 //     console.log('CONTROL - Error - onReactionComplete(): ', err);
    }
  }

  private startReaction() {
 //   console.log('CONTROL - Starting....')
    this.startReveal();
    this.OverlayLoopController_top();
    this.OverlayLoopController_bottom();
    this.setupProgessSpinner();
    this.prog_animation = true;
    this.isPaused = false;
    this.spinLoopCurrentCount = 0;
    this.currentLoopIndex_bottom = 0;
    this.videoPoster = this.reaction_data?.product?.product_details?.image_src 
    this.stepGoto = 10;           //set to default next transition step
  }

  private endReaction(userExit: boolean){
 //   console.log('CONTROL - end.  User initiated: ' + userExit);
    this.startRecording = false;
    this.exitCondition = true;
    this.spinLoopCurrentCount = this.spinLoopMaxCount + 1;
    this.currentLoopIndex_bottom = this.config_ordered_bottom.length + 1;
 //   console.log('CONTROL - Exit stat: spinloop count: ' +this.spinLoopCurrentCount + ' overlay count: ' + this.currentLoopIndex );
  }
  
  private faultDetected(type: string) {
    console.log('RECORD - fault detected: ' + type);
    this.endReaction(false);
    this.telemetryRecord(14);
    this.app_state.reactionStepValue$.next(14);       //this is a fault condition and we are routing to it.
  }

  moveToNextStep($event: boolean) {       //this will be called by the webcam component when it is finished.
 //   console.log('CONTROL - webcam think it is all done.')
    this.telemetryRecord(this.stepGoto);
    this.app_state.reactionStepValue$.next(this.stepGoto);
  }

  //**********        this is html exposed functions */
  //**********        this is html exposed functions */
  //**********        this is html exposed functions */
  //**********        this is html exposed functions */

  btnStart() {
 //   console.log('btnStart');
    this.startReaction();
  }

  btnBack() {
 //   console.log('btnBack');
    this.telemetryRecord(1);
    this.app_state.reactionStepValue$.next(1)
  }

  btnRestart() {
  //  console.log('BTN - Restart');
    this.endReaction(true);
    this.stepGoto = 5;      //this step number
 //   console.log('BTN - Restart - route to: ' + this.stepGoto);
    this.telemetryRecord(this.stepGoto);
    this.app_state.reactionStepValue$.next(this.stepGoto);
  }

  btnAction() {
  //  console.log('btnAction');
    this.ActionControl()
  }

  btnDone() {
 //   console.log('btnDone');
    this.stepGoto = 10;
    this.endReaction(true);           //true measn the user choose to complete early.
  }

  videoReady(){
    console.log('Video ready');
    this.btnTxt_primary_init = this.host_config.app_content.experience.step.step5.prmy_btn_int
    this.videoLoaded = true;
  }

//**********        this is helper block */
//**********        this is helper block */
//**********        this is helper block */
//**********        this is helper block */

  onVideoMetadataLoaded() {
    const videoElement: HTMLVideoElement = this.reveal_video.nativeElement;
    this.videoDuration = videoElement.duration;
    console.log("Video duration: " + this.videoDuration);
  }

  public updateCurrentTime() {
   // console.log('Time update: ' + this.reveal_video.nativeElement.currentTime);
    this.currentTime = this.reveal_video.nativeElement.currentTime;
    if (this.reveal_video.nativeElement.currentTime === 0 &&  this.videoCompleted != true){
    //  this.faultDetected();
    }
  }
  

  /*
  public updateCurrentVideoTime() {           
        //this is unnecasary on this component
  }


  async routeToPrivacy() {
    // return this.appRouter.navigate(this.appRouter.routes.tos);
  }

  getTime = () =>
    this.currentTime > this.duration ? this.duration : this.currentTime;

*/

}
