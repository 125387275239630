import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { AppHost } from '@yoyo/types';
import { environment } from '@yoyo/env';
import { Empty$, GetVal, GetVal$, SetVal } from '@yoyo/shared';
import { AuthService } from '@yoyo/services';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HostStateService  {
  private host_config = Empty$<AppHost>('host_config');
  private host_name = Empty$<string>('host_name');
  host_available: boolean = false;
  private reactionId: string ="";

  constructor(private http: HttpClient, private authService: AuthService) {}
  
  async initHostEnvironment() {
    var checkHost = document.location.hostname;
    var setHost;
    if (checkHost.includes("ngrok")) {
      setHost = "localhost"
    } else {
        setHost = document.location.hostname;
    }

  // setHost = "localhostv2-not_there"     //testing and development override
    const hostName = setHost;
    //console.log('Host is: ' + hostName);

    this.setHostName(hostName);
    const host_config = await this.retriveHostConfig(hostName);

   // console.log('should have the host config.  It is: ' + JSON.stringify(host_config, null, 2));

    this.setHostConfig(host_config);
    this.setLocalStyleClasses(host_config);
    this.loadDynamicFonts([ host_config.theme.font_family]);
  }


  setLocalStyleClasses(host_config: AppHost) {
    const css = `
    .host-primary-bg { background-color: ${host_config.theme.primary_color} !important;}
    .host-accent-bg { background-color: ${host_config.theme.accent_color} !important;}
    .host-primary { color: ${host_config.theme.primary_color} !important;}
    .host-accent { color: ${host_config.theme.accent_color} !important;}
    .host-primary-btn { background-color: ${host_config.theme.primary_btn.fill_color} !important; color: ${host_config.theme.primary_btn.txt_color} !important;}
    .host-accent-btn { background-color: ${host_config.theme.accent_btn.fill_color} !important; color: ${host_config.theme.accent_btn.txt_color} !important;}
    .host-border-primary { border-color: ${host_config.theme.primary_color} !important;}
    .host-system-bg { background-repeat: no-repeat !important; background-size: cover !important; background-position: 0; background-image: url(${host_config?.theme?.background_url}) !important; background-color: ${host_config.theme.primary_color} !important; }
    `,
      head = document.head || document.getElementsByTagName('head')[0],
      style = document.createElement('style');

    head.appendChild(style);
    style.appendChild(document.createTextNode(css));
  }


    loadDynamicFonts(fonts: string[]) {
      const linkElement = document.createElement('link');
      linkElement.setAttribute('rel', 'stylesheet');
      linkElement.setAttribute('type', 'text/css');
    
      // Construct the URL for Google Fonts
      let fontFamilies = fonts.map(font => `family=${font.replace(/\s+/g, '+')}:wght@300;400;600`).join('&');
      linkElement.setAttribute('href', `https://fonts.googleapis.com/css2?${fontFamilies}&display=swap`);
    
      // Append the link element to the head
      document.head.appendChild(linkElement);
    
      // Optionally set a CSS variable or class to use the loaded font(s)
      document.documentElement.style.setProperty('--primary-font', fonts[0]);
    }



  
  async retriveHostConfig(host_name: string) {
    //hostname check for netifly to allow use of localhost config from firebase
    if (host_name.includes('netlify.app')) {
      console.log('Netilfy has been detected and the hostname has been changed to localhost.');
      console.log('Add reaction id to the URL');
      host_name = 'localhost';
    };
    try {
      
    //  console.log('Getting host: ' + host_name);
      const authToken =  'Bearer ' + this.authService.getAuthToken();
    //  console.log('authToken ' + authToken);
      const response = await this.http
        .post<AppHost>(
          environment.api.host.retrieve,
          {
            host_name,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization' : authToken,
            },
            responseType: 'json',
          }
        )
        .toPromise();
   //   console.log('Got a host: ' + JSON.stringify(response, null, 2));
      // If the request is successful, set host_available to true
      this.host_available = true;

      return response;
    } catch (e) {
      console.log('error is: ' + JSON.stringify(e, null, 2));
      this.host_available = false;
      throw e;
    }
}


  setHostConfig(host_config: AppHost): void {
    SetVal(this.host_config, host_config);
  }

  get currentHostConfig(): AppHost {
    return GetVal(this.host_config);
  }
  get currentHostConfig$(): Observable<AppHost> {
    return GetVal$(this.host_config);
  }

  setHostName(host_name: string): void {
    SetVal(this.host_name, host_name);
  }

  get currentHostName(): string {
    return GetVal(this.host_name);
  }
  get currentHostName$(): Observable<string> {
    return GetVal$(this.host_name);
  }
}
