import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as firebase from 'firebase/app';
import { environment } from '@yoyo/env';
import { CloudStorageService } from '@yoyo/services';

@Injectable({
    providedIn: 'root'
  })

export class AuthService {
    private authToken: any;
    private authServiceToken: any;
    constructor(private http: HttpClient, private fs: CloudStorageService){ this.fs.initFirebase();}

    async getToken(): Promise<void> {
        try {
            //console.log('we are go for auth get');
            await firebase.default.auth().signInWithEmailAndPassword(environment.account.email,environment.account.password)
            const authToken = await firebase.default.auth().currentUser.getIdToken()
            this.authServiceToken = authToken;
            this.authToken = authToken;
            //console.log()
        } catch (error) {
            console.log('error: ' + JSON.stringify(error))
        }
    }

    getAuthToken(): string {
        return this.authToken;
      }

    getAuthServiceToken(): string {
        return this.authServiceToken;
    }
}