import {Component, Renderer2, ElementRef, AfterViewInit, OnInit} from '@angular/core';
import { AppHost, Reaction } from '@yoyo/types';
import {HostStateService, AppStateService, ReactionService, TelemetryService} from '@yoyo/services';
import {DynamicComponent} from '../../setup/setup.component';

@Component({
  selector: 'step-write-response',
  templateUrl: './write.component.html',
})

export class ReactionWriteResponseStep implements DynamicComponent, OnInit, AfterViewInit  {   //step 8
  current_reaction: Reaction;
  current_host_config: AppHost;
  replyMSGUser: string = '';
  prmyBtnText: string = "Video";
  backBtnText: string = "Back";
  overlayValue: string;
  imgSrc: string;
  uploading: boolean = false;

  constructor(
    private app_state: AppStateService,
    private host_state: HostStateService,
    private rs: ReactionService,
    private renderer: Renderer2,
    private el: ElementRef,
    private telemetry: TelemetryService,
  ) {
    this.telemetry.startRecordingLoad();
    this.current_reaction = this.app_state.current_reaction;
    this.current_host_config = this.host_state.currentHostConfig;
    this.prmyBtnText = this.current_host_config.app_content.experience.step.step8.prmy_btn;
    this.backBtnText = this.current_host_config.app_content.experience.step.step8.scnd_btn;
    this.overlayValue = this.replaceConfigString(this.current_host_config.app_content.experience.step.step8.overalLayValue);
    this.imgSrc = this.replaceConfigString(this.current_host_config.app_content.experience.step.step8.img);
  }

    async ngOnInit() {
      const termsLink = this.el.nativeElement.querySelector('#termsLink');

      if (termsLink) {
        this.renderer.listen(termsLink, 'click', (event) => {
          console.log('link listened to');
          event.preventDefault();
          this.app_state.returnStep = 8;
          this.app_state.reactionStepValue$.next(13);
        });
      }
    }

    async ngAfterViewInit() {
      this.telemetry.stopRecordingLoad();
      this.telemetry.startRecordingUser();
    }

    private telemetryRecord(next: number) {
      this.telemetry.stopRecording(8, this.current_host_config?.app_content?.experience?.step?.step8?._module, next);
    //  console.log('step telemetery is: ' + JSON.stringify(this.telemetry.telemetryData.steps, null,2));
    }

    async sendMessage() {
      console.log('sendMessage triggered');
      this.uploading = true;
      console.log('replyMSGUser: ' + this.replyMSGUser);
      this.telemetryRecord(9);
      this.telemetry.setStatus("msg sent");
      this.telemetry.postTelemetry().subscribe();     //this will post the telemetry.
      const message = this.replyMSGUser;
      await this.rs.saveMessage(message);
      this.app_state.reactionStepValue$.next(9);
      this.uploading = false;
    }

    async backBtn() {
      this.telemetryRecord(6);
      this.app_state.reactionStepValue$.next(6)
    }

    private replaceConfigString(string_value: string) {
      return string_value
        .replace(
          '{{sender.first_name}}',
          this.current_reaction?.sender_details?.first_name
        )
        .replace(
          '{{sender.last_name}}',
          this.current_reaction?.sender_details?.last_name
        )
        .replace(
          '{{receiver.first_name}}',
          this.current_reaction?.receiver_details?.first_name
        )
        .replace(
          '{{gift_name}}',
          this.current_reaction?.product?.product_details?.label || ''
        )
        .replace(
          '{{gift_image}}',
          this.current_reaction?.product?.product_details?.image_src || ''
        )
        .replace(
          '{{gift_description}}',
          this.current_reaction?.product?.product_details?.product_description || ''
        )
        .replace('{{gift_message}}', this.current_reaction?.gift_message || '');
    }

}