import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms'; 

import {
  SharedComponentsModule,
  SharedPipesModule,
  ReactionStepDirective,
} from '@yoyo/shared';
import { LayoutsModule } from '@yoyo/layouts';

import {
  CreateReactionComponent,
  ReactionSetupStep,
  ReactionModeStep,
  ReactionPermissionRequestStep,
  ReactionPermissionDeniedStep,
  ReactionRecordStep,
  ReactionWatchStep,
  ReactionResponseOptionsStep,
  ReactionRecordPostResponseStep,
  ReactionWriteResponseStep,
  ReactionOfferStep,
  RecordReviewStep,
  ReactionSlowStep,
  ReactionSimpleShowStep,
  ReactionLegalStep,
  ReactionRetryFaultStep,
  ReactionConfigfailStep
} from '@yoyo/pages';
import { NgCircleProgressModule } from 'ng-circle-progress';

const reaction_steps = [
  ReactionSetupStep,
  ReactionModeStep,
  ReactionPermissionRequestStep,
  ReactionPermissionDeniedStep,
  ReactionRecordStep,
  ReactionWatchStep,
  ReactionResponseOptionsStep,
  ReactionRecordPostResponseStep,
  ReactionWriteResponseStep,
  ReactionOfferStep,
  RecordReviewStep,
  ReactionSlowStep,
  ReactionSimpleShowStep,
  ReactionLegalStep,
  ReactionRetryFaultStep,
  ReactionConfigfailStep
];

const components = [
  CreateReactionComponent,
  ReactionStepDirective,
  ...reaction_steps,
];

@NgModule({
  declarations: [AppComponent, ...components],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedComponentsModule,
    HttpClientModule,
    LayoutsModule,
    SharedPipesModule,
    NgCircleProgressModule.forRoot({
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: '#78C000',
      innerStrokeColor: '#C7E596',
      animationDuration: 300,
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
