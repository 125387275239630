import { Component, OnInit } from '@angular/core';
import { HostStateService,  AppStateService, TelemetryService, AuthService} from '@yoyo/services';

@Component({
  selector: 'app-root',
  template: `
    <div *ngIf="!isResolvingAppState">
      <router-outlet></router-outlet>
    </div>
    <div *ngIf="isResolvingAppState">
      <app-loader> </app-loader>
    </div>
  `,
})
export class AppComponent implements OnInit {
  isResolvingAppState = true;
  constructor(
    private hs: HostStateService,
    private app_state: AppStateService,
    private telemetry: TelemetryService,
    private auth: AuthService
    ) {}
    
  async ngOnInit() {
    try {
      this.app_state.curStatus = "Step 1 of 3 - checking authorisation";
      await this.auth.getToken();
      this.app_state.curStatus = "Step 2 of 3 - Authorised - downloading host configutation";
      await this.hs.initHostEnvironment();
      this.app_state.curStatus = "Step 3 of 3 - Getting gift information";
      this.isResolvingAppState = false;

    } catch (e) {
      console.log('Critical Error: App Config', e);
      this.isResolvingAppState = false;
    }
  }
}
