import {Component, ElementRef, OnInit, Renderer2, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { Subject, interval } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppHost, Reaction } from '@yoyo/types';
import { HostStateService, AppStateService, TelemetryService } from '@yoyo/services';
import {DynamicComponent} from '../setup/setup.component';

@Component({
  selector: 'step-watch',
  templateUrl: './watch.component.html',
})
export class ReactionWatchStep implements OnInit, OnDestroy, DynamicComponent, AfterViewInit  {           //step 2
  reaction_data: Reaction;
  @ViewChild('reveal_video') reveal_video: ElementRef<HTMLVideoElement>;
  current_reaction: Reaction;
  isXLBreakpoint = false;
  private timeExtend: number = 0;


  //Progress
  percentProgVal: number = 0;
  startZero: boolean = true;
  spinTitle: string = "";
  spinner_duration = 0;
  prog_animation = false;
  private overalDuration: number = 0;
  private spinLoopMaxCount: number = 0;
  private spinLoopCurrentCount: number = 0;
  private spinDecrement: number = 100;    //this is the await value in the spin - feeds into the spinLoopCount
  
  // Overlays
  revealContentShown: boolean = false;
  reveal_overlay_content = '';
  private config_ordered: any;
  private currentLoopIndex: number = 0;
  private timeLeft: number = 0;
  private timeConsumed: number = 0;
  private destroy$: Subject<void> = new Subject<void>();

  // State Controls
  is_reveal_video_ready = false;
  has_reaction_started = false;
  devicesError = false;
  host_config: AppHost;
  videoDuration: number;
  private isPaused: boolean = true;
  videoCompleted: boolean = false;  
  private exitCondition: boolean = false;  

   //button labels
   btnTxt_primary_init: string = "";
   btnTxt_scnd_init: string = "";
   btnTxt_primary_act: string = "";
   btnTxt_scnd_act: string = "";
  actionBtn: string = "pause";

  
  // video
  videoPoster: string;
  watchBG: string
  videoLoaded: boolean = false;


  constructor(
    private app_state: AppStateService,
    private hs: HostStateService,
    private renderer: Renderer2,
    private telemetry: TelemetryService,
  ) {
    this.telemetry.startRecordingLoad();
    this.current_reaction = this.app_state.current_reaction;
    this.reaction_data = this.app_state.current_reaction;
    this.host_config = this.hs.currentHostConfig;
    this.timeExtend = this.host_config.time_control?.extend_watch_length;
    this.videoPoster = this.host_config?.video_posters?.watch_video_poster;  
  }


//**********        LifeCycle hooks */
//**********        LifeCycle hooks */
//**********        LifeCycle hooks */
//**********        LifeCycle hooks */

  async ngOnInit() {
    console.log("watch step initialised");

    this.btnTxt_primary_init = this.host_config.app_content.experience.step.step2.prmy_btn_int;
    this.btnTxt_scnd_init = this.host_config.app_content.experience.step.step2.scnd_btn_int;
    this.btnTxt_primary_act = this.host_config.app_content.experience.step.step2.prmy_btn_act;
    this.btnTxt_scnd_act = this.host_config.app_content.experience.step.step2.scnd_btn_act; 



     this.checkImgForBG().then((imageUrl) => {
        this.watchBG = imageUrl;
      }).catch((error) => {
          this.watchBG = 'assets/images/watchOverlayBG.png';
      });
    
    try {
      this.devicesError = await this.checkEnumerateDevices();
      if (!!this.devicesError) {
        alert('Device not supported!');
        // TODO JM: Handle error on DOM
      }
      this.is_reveal_video_ready = true;
    } catch (error) {
      console.error('Error: ngOnInit()', error);
    }

    if (window.innerWidth >= 1024){
      console.log('Window size: ' + window.innerWidth);
      this.isXLBreakpoint = true;
    } else {
      this.isXLBreakpoint = false;
   //   console.log('size is not xl because: ' + window.innerWidth);
    }

    this.config_ordered = this.host_config?.overlays?.exp_watch?.sort(
      (a, b) => a.index - b.index
    );
   // console.log('config_ordered: ' + this.config_ordered);
    //this.gobackBtnText = "Back";
  }

  async ngAfterViewInit() {
    this.telemetry.stopRecordingLoad();
    this.telemetry.startRecordingUser();
  }

  private telemetryRecord(next: number) {
    this.telemetry.stopRecording(2, this.host_config?.app_content?.experience?.step?.step2?._module, next);
   // console.log('step telemetery is: ' + JSON.stringify(this.telemetry.stepTrackArray, null,2));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

//**********        this is the progress code block */
//**********        this is the progress code block */
//**********        this is the progress code block */
//**********        this is the progress code block */

  private async setupProgessSpinner() {
    this.overalDuration = this.videoDuration + this.timeExtend;
    console.log('WATCH - overalDuration: ' + this.overalDuration);
    this.spinner_duration = this.overalDuration * 1000;
    //this.percentProgVal = this.overalDuration;
    this.spinLoopMaxCount = this.spinner_duration / this.spinDecrement;
    //console.log('spinLoopMaxCount is set at: ' + this.spinLoopMaxCount);

    this.spinnerBurnDown();
  };

  private async spinnerBurnDown() {
    let curPercent = 0;
    for (let counter = this.spinLoopCurrentCount; counter < this.spinLoopMaxCount;) {
      curPercent = (this.spinLoopCurrentCount/this.spinLoopMaxCount) * 100;
      this.percentProgVal = 100 - curPercent;
   //   console.log('SPINNER - percentProgVal: ' + this.percentProgVal + ' spinLoopCurrentCount= ' + this.spinLoopCurrentCount + ' spinLoopMaxCount= ' + this.spinLoopMaxCount);
      this.spinTitle = Math.round((this.percentProgVal/100) * this.overalDuration).toString();
      
      await this.delay(this.spinDecrement);
      if (this.exitCondition) {
  //      console.log('WATCH - spinnerBurn down exit condition')
        return;
      } else {
        if (this.isPaused){
            return    //exist this loop becaue pause duration is unknown.  Will start again on resume.
        } else {
          counter++;
          this.spinLoopCurrentCount = counter;
        }

        if (counter >= this.spinLoopMaxCount){
    //      console.timeLog('WATCH - Burndown complete')
          this.endReaction(false);        //false means process ended via the timer completing.  Note true means users exited
        }
      }
    }
  }

  private async delay(ms: number): Promise<void> {
    return new Promise<void>(resolve => setTimeout(resolve, ms));
  }


//**********        this is the overlay code block */
//**********        this is the overlay code block */
//**********        this is the overlay code block */
//**********        this is the overlay code block */

  private async startCountdown(waiting: number): Promise<number> {
    //console.log('starting the time for: ' + waiting);
    let timeConsumedLocal: number = 0;
    const completedPromise = new Promise<number>((resolve) => {
      interval(100)
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe(count => {
          const remainingTime = waiting - count / 10;
          timeConsumedLocal = count / 10;
          this.timeLeft = Math.max(remainingTime, 0);
          if (this.timeLeft <= 0) {
            resolve(0);
          }
        });

        this.destroy$.subscribe(() => {
      //    console.log('destroy$ emitted in startCountdown');
            resolve(timeConsumedLocal); // Resolve the promise when destroy$ is emitted
        });
    });
    return await completedPromise; // Wait until the promise is resolved (countdown is completed)
  }

  private async OverlayLoopController() {
    //console.log('OVERLAY - loop about to start from: ' + this.currentLoopIndex);
    for (let counter = this.currentLoopIndex; counter < this.config_ordered.length;) {
      //console.log('config is: ' + JSON.stringify(this.config_ordered[i], null, 2));
      if (this.isPaused) {
        // Pause the loop if the overlay timer is paused
        this.currentLoopIndex = counter;
  //      console.log('paused loop exit. ' + this.currentLoopIndex);
        return;
      }

      if(!this.revealContentShown) {      //false means we must be waiting to show
          const waiting = (this.config_ordered[counter].show_at/1000) - this.timeConsumed;
  //        console.log('OVERLAY - SHOW block true. Stats: waiting='+waiting + ' config val='+this.config_ordered[counter].show_at+' timeConsumed=' + this.timeConsumed);
          this.timeConsumed = await this.startCountdown(waiting) +  this.timeConsumed;
  //        console.log("SHOW - await complete with timeConsumed = " + this.timeConsumed);
          
          if (this.exitCondition) {
  //          console.log('WATCH - OverlayLoopController down exit condition @shown await')
            return;
          } else { 
            if (this.isPaused){
  //            console.log('doing nothing because paused');
            } else {
              this.timeConsumed = 0;
              this.revealContentShown = true;
   //           console.log('Show phase complete for loop: ' + this.currentLoopIndex);
            }
          }
      }
      
      if (this.revealContentShown){
          this.reveal_overlay_content = this.replaceConfigString(this.config_ordered[counter].message);
          const waiting = (this.config_ordered[counter].hide_at/1000) - this.timeConsumed;
 //         console.log('OVERLAY - HIDE block true. Stats: waiting='+waiting + ' config val='+this.config_ordered[counter].hide_at+' timeConsumed=' + this.timeConsumed);
          this.timeConsumed = await this.startCountdown(waiting) +  this.timeConsumed;
 //         console.log("HIDE - await complete with timeConsumed = " + this.timeConsumed);
          if (this.exitCondition) {
 //           console.log('WATCH - OverlayLoopController down exit condition @hide await')
            return;
          } else {        
            if (this.isPaused){
 //             console.log('doing nothing because paused');
            } else {
              this.timeConsumed = 0;
              this.revealContentShown = false;
 //             console.log('Hide phase complete for loop: ' + this.currentLoopIndex);
              this.currentLoopIndex++;
              counter++;
            }
          }
      }
 //     console.log('loop end with currentLoopIndex: ' + this.currentLoopIndex);
    }
  }

  private replaceConfigString(string_value: string) {
    return string_value
      .replace(
        '{{sender.first_name}}',
        this.reaction_data?.sender_details?.first_name
      )
      .replace(
        '{{sender.last_name}}',
        this.reaction_data?.sender_details?.last_name
      )
      .replace(
        '{{receiver.first_name}}',
        this.reaction_data?.receiver_details?.first_name
      )
      .replace(
        '{{gift_name}}',
        this.reaction_data?.product?.product_details?.label || ''
      )
      .replace(
        '{{gift_image}}',
        this.reaction_data?.product?.product_details?.image_src || ''
      )
      .replace(
        '{{gift_description}}',
        this.reaction_data?.product?.product_details?.product_description || ''
      )
      .replace('{{gift_message}}', this.reaction_data?.gift_message || '');
  }

  private async checkEnumerateDevices() {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      if (Array.isArray(devices) && !!devices.length) {
   //     console.log('Device detected');
        return false;
      } else {
        return true;
      }
    } catch (err) {
      return true;
    }
  }

//**********        this is the control block */
//**********        this is the control block */
//**********        this is the control block */
//**********        this is the control block */


  private async startReveal(): Promise<void> {
   // console.log("start reveal")
    try {
      this.has_reaction_started = true;
      this.videoDuration = this.reveal_video.nativeElement.duration;
   //   console.log('video duration '+ this.videoDuration);

      this.isPaused = false;
      this.startZero = false;
      //play condition
      this.reveal_video.nativeElement.play();
  
      this.renderer.listen(this.reveal_video.nativeElement, 'play', () => {
        
      });

      this.renderer.listen(this.reveal_video.nativeElement, 'pause', () => {
      
      });

      this.renderer.listen(this.reveal_video.nativeElement, 'ended', () => {
   //     console.log('Video element ended');
        this.videoEnd();
      });

    } catch (error) {
      console.error('Error: ngOnInit()', error);
    }
  }

  private videoEnd() {
   // console.log('Video end control function initiated');
    this.videoCompleted = true;
    this.prog_animation = true;
    this.videoPoster = this.reaction_data?.product?.product_details?.image_src    //changing video poster bc I can and might be useful.
    this.reveal_video.nativeElement.load();         //displaying the poster on video end.
  }

  private ActionControl() {
   // console.log('SPINNER - percentProgVal: ' + this.percentProgVal + ' spinLoopCurrentCount= ' + this.spinLoopCurrentCount + ' spinLoopMaxCount= ' + this.spinLoopMaxCount);
    if (this.isPaused) {        //paused so resuming
   //   console.log('CONTROL - state = paused -> action = resume.')
      this.actionBtn = this.host_config.app_content.experience.step.step2.thrd_btn_act1;
      this.isPaused = false;
      this.prog_animation = true;
      this.OverlayLoopController();
      this.spinnerBurnDown();         
      if (!this.videoCompleted){                      //video still playing so resuming
          this.reveal_video.nativeElement.play();     // Resume video 
      }                                               // no else bc video is done - condition of overal going longer than video
    } else {                                          //video still playing so resuming
  //    console.log('CONTROL - state = playing -> action = pause')
      this.actionBtn = this.host_config.app_content.experience.step.step2.thrd_btn_act2;
      this.prog_animation = false;
      this.isPaused = true;
      this.destroy$.next();
      if (!this.videoCompleted){ 
        this.reveal_video.nativeElement.pause(); // Resume video
      }
    }
  }

  private endReaction(userExit: boolean){
 //   console.log('CONTROL - end.  User initiated: ' + userExit);
    this.exitCondition = true;
    this.spinLoopCurrentCount = this.spinLoopMaxCount + 1;
    this.currentLoopIndex = this.config_ordered.length + 1;
    //console.log('CONTROL - Exit stat: spinloop count: ' +this.spinLoopCurrentCount + ' overlay count: ' + this.currentLoopIndex );
    this.routeToResponseOptions();
  }

  routeToResponseOptions() {
    this.telemetryRecord(6);
    this.app_state.reactionStepValue$.next(6);
  }

//**********        this is html exposed functions */
//**********        this is html exposed functions */
//**********        this is html exposed functions */
//**********        this is html exposed functions */

  btnStart() {
  //  console.log('btnStart');
    this.startReveal();
    this.OverlayLoopController();
    this.setupProgessSpinner();
    this.prog_animation = true;
    this.isPaused = false;
  }

  btnBack() {
    //console.log('btnBack');
    this.telemetryRecord(1);
    this.endReaction(true); 
    this.app_state.reactionStepValue$.next(1)
  }

  btnRestart() {
    //console.log('btnRestart');
    this.telemetryRecord(2);
    this.endReaction(true); 
    this.app_state.reactionStepValue$.next(2);
  }

  btnAction() {
    //console.log('btnAction');
    this.ActionControl()
  }

  btnDone() {
    //console.log('btnDone');
    this.endReaction(true);  
  }



//**********        this is helper block */
//**********        this is helper block */
//**********        this is helper block */
//**********        this is helper block */

  private checkImgForBG(): Promise<string> {
    return new Promise((resolve) => {
      const img = new Image();

      img.onload = () => {
        console.log('WATCH BG image ok');
        resolve(this.host_config?.app_content?.experience?.step?.step2?.img);
      };

      img.onerror = () => {
        console.log('WATCH BG image not configured.  Reverting to local.');
        resolve('assets/images/watchOverlayBG.png');
      };

      img.src = this.host_config?.app_content?.experience?.step?.step2?.img;
    });
  }

  onVideoMetadataLoaded() {
    const videoElement: HTMLVideoElement = this.reveal_video.nativeElement;
    this.videoDuration = videoElement.duration;
    this.btnTxt_primary_init = this.host_config.app_content.experience.step.step2.prmy_btn_int
    this.videoLoaded = true;
    //console.log("Video duration: " + this.videoDuration);
  }

  public updateCurrentVideoTime() {           
        //this is unnecasary on this component
  }
}
